/* Return.css */
.return-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0; /* Reset any default margins */
    padding: 0; /* Reset any default padding */
    transition: all 0.2s ease;
}

p {
    width: 60%; /* Set the width of the text */
    text-align: center;
    font-size: 1.5rem; /* Set the font size */
    margin: 5 vh 0; /* Reset any default margins */
    padding: 0; /* Reset any default padding */
     /* Ensure the text takes the full width of the container */
}

a {
    color: inherit; /* Inherit the color from the parent element */
    text-decoration: none;
    border-bottom: 1px solid rgba(177,177,177,1);
}

a:hover {
    border-bottom: 1px solid rgb(0, 0, 0, 1);
}

.p-mobile {
    width: 80%;
}
