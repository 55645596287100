/* create an id */

.checkout-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  transition: all 2s;
}

#scroll-to {
  height: 0;
}

.checkout-frame-mobile {
  padding: none;
}