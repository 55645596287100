.product {
    border: 1px solid #ccc;
    padding: 16px;
    margin: 16px 0;
  }
  
  .product-title {
    font-size: 24px;
    margin-bottom: 8px;
  }

  .product-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
    width: calc(var(--poster-max-width) / 3);
    padding-top: var(--ticket-vertical-padding);
    height: calc((var(--poster-max-width) / 3) / 1.618);
    transition: all 0.2s ease;
}

.product-tile-expanded {
    height: calc(50vh - var(--ticket-vertical-padding) * 2);
}

.product-tile-not-first {
    border-left: 1px solid rgba(177,177,177,1); /* Add a border between tiles */
}


.reserve-or-increment-container {
  display: flex;
  flex-direction: column;
  /* background-color: tomato; */
  justify-content: center;
  align-items: center;
  padding: calc(var(--ticket-vertical-padding) / 2);
  width: calc((var(--poster-max-width) / 11));
  height: calc((var(--poster-max-width) / 33));
}

.reserve-button {
  display: inline-block;
  /* width: calc(var(--poster-max-width) / 11); */
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(177,177,177,1);
  font-size: 16px;
  color: rgb(30, 30, 30);
  cursor: pointer;
  transition: all 0.2s ease;
}

.reserve-button:hover {
  border-bottom: 1px solid rgba(177,177,177,1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.product-details {
  display: flex;
  flex-direction: column;
}


.product-marketing-features-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: none;
}

.product-marketing-features-container-expanded {
  display: flex;
} 
  
  .expand-features {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .features {
    display: none;
    margin-top: 16px;
  }
  
  .features.expanded {
    display: block;
  }

  