/* Poster.css */
.poster {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure no overflow */
}

.poster-image {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.poster-image img {
    height: 80vh;
    box-sizing: border-box;
    padding: 10vh 0;
    max-width: 80vw;
    max-height: 100%;
    height: auto;
    width: auto;
    display: block;
}

.poster-text-container {
    width: 80vw;
    text-align: center;
}

.poster-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.poster-title {
    font-size: 1vh;
    white-space: nowrap;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.poster-text h1 {
    font-size: 7vh;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.poster-text h2 {
    font-size: 5vh;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.poster-text h3 {
    font-size: 1.8rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

.poster-text p {
    font-size: 3rem;
    align-content: start;
}

#poster-times {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3vh;
}

#poster-times div {
    flex: 1;
    text-align: center;
    width: 25vh;
}

#poster-address {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 3vh;
}

#poster-address h4 {
    flex: 1;
    text-align: center;
    width: 25vh;
    /* padding: 10vw; */
}

