@media screen {
    /* create variable */
    :root{
        --poster-padding: 10vh;
        --poster-max-width: 80vw;
        --poster-max-height: 80vh;
        --ticket-vertical-padding: 32px;
    }
}

