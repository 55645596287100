@import url('variables.css');

.quantity-controls-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: calc(var(--ticket-vertical-padding) / 4);
    color: rgb(30, 30, 30);
    transition: all 0.2s ease;
}


.increment-button, .decrement-button {
    display: inline-block;
    text-align: center;
    width: calc(var(--poster-max-width) / 33);
    /* height: calc(var(--poster-max-width) / 33); */
    aspect-ratio: 1;
    background-color: white;
    color: rgb(30, 30, 30);
    font-size: 16px;
    border: none;
    border-bottom: 1px solid rgba(177,177,177,0);
    cursor: pointer;
    transition: all 0.2s ease;
}

.increment-button-mobile, .decrement-button-mobile {
    width: calc(var(--poster-max-width) / 11);
    height: calc(var(--poster-max-width) / 11);
}

.quantity-selector {
    width: calc(var(--poster-max-width) / 33);
    aspect-ratio: 1;
    -webkit-appearance: none; /* For Safari and Chrome */
    -moz-appearance: none; /* For Firefox */
    appearance: none; /* Standard syntax */
    /* margin-top: 16px; */
    border: none;
    border-radius: none;
    border-bottom: 1px solid rgba(177,177,177,0);
    cursor: pointer;
    transition: all 0.2s ease;
    font: inherit;
    text-align: center;
}

.decrement-button:active {
    transform: translateY(3px);
    /* background-color: black; */
}

.increment-button:active {
    transform: translateY(-3px);
}


/* use media queries */
/* Disable hover effects on mobile devices */
@media (hover: hover){
    .increment-button:hover, .decrement-button:hover, .quantity-selector:hover {
        border-bottom: 1px solid rgba(177,177,177,1);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
}