@import url('variables.css');

body {
    font-family: "josefin-sans", sans-serif;
    font-style: normal;
}

.parent-product-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; 
    transition: all 0.2s ease;
}

.product-container {
    display: flex;
    justify-content: center; /* Center the tiles horizontally */
    align-items: flex-start; /* Align the tiles to the top */
    width: calc(var(--poster-max-width))
    /* height: 100vh; Full viewport height */
}

.product-container-mobile{
    flex-direction: column;
    align-items: center;
}

.product-container-checkout {
    border-bottom: 1px solid rgba(177,177,177,1);
}

.invisible {
    opacity: 0 !important;
}

.checkout-button {
    display: none;
    justify-content: center;
    align-items: center;
    width: 250px;
    margin: 0 auto;
    width: calc(var(--poster-max-width) / 3);
    cursor: pointer;
    font-size: 32px;
    border-bottom: 1px solid rgba(177,177,177,0);
}

@media (hover: hover){
    .checkout-button:hover {
        /* border-bottom: 1px solid rgba(177,177,177,1); */
        border-bottom: 1px solid rgba(177,177,177,1);
        box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2); /* Adjusted box-shadow */
    }
}

.checkout-button-mobile {
    display: flex;
    position: fixed;
    font-size: 32px;
    bottom: -10vh;
    left: 0;
    height: 10vh;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top: 1px solid rgba(177,177,177,1);
    z-index: 1000; /* Ensure it stays on top */
    transition: all 0.2s ease;
}

.checkout-button-visible {
    display: flex;
    bottom: 0;
}

.checkout-button-mobile-checkout-requested {
    position: relative;
    border-top: 0;
    border-bottom: 1px solid rgba(177,177,177,1);
}

.checkout-button-checkout-loading {
    animation: loading 1s infinite ease-out;
}

/* make an animation that fades the opacity of a div */
@keyframes loading {
    0% {
        color: black;
    }
    50% {
        color: white;
    }
    100% {
        color: black;
    }
}


.product-name {
    font-size: 1.2em;
    font-weight: 700;
    margin-bottom: 10px;
}

.product-images {
    width: 100px; /* Set the width of the image */
    height: auto; /* Maintain the aspect ratio */
    margin-bottom: 10px;
    border-radius: 100%;
}

.product-price {
    font-size: 1em;
    font-weight: 500;
    color: #333;
}


/* use media queries */

@media (max-width: 1022),
        (min-resolution: 192dpi) and (max-width: 1022px),
        (min-resolution: 288dpi) and (max-width: 1022px)
 {

    .product-container {
        
    }

}