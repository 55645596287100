/* set variables */

:root {
    --mobile-tile-base-height: calc(100vh / 3);
    --golden-ratio: 1.618;
    --mobile-tile-base-golden-height: calc(var(--mobile-tile-base-height) / var(--golden-ratio));
    --mobile-tile-base-golden-leftover-height: calc(var(--mobile-tile-base-height) - var(--mobile-tile-base-golden-height));
}

.product-mobile {
    border: 1px solid #ccc;
    padding-bottom: 16px;
  }


  .product-mobile-tile {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: var(--mobile-tile-base-golden-height) var(--mobile-tile-base-golden-leftover-height);
    align-items: center;
    justify-content: flex-start;
    box-sizing: content-box;
    width: 100%; /* Set the width of the tile */
    height: var(--mobile-tile-base-height);
    transition: all 0.2s ease;
}

.product-mobile-tile-expanded {
    height: calc(var(--mobile-tile-base-height) * 2);
    grid-template-rows: var(--mobile-tile-base-golden-height) var(--mobile-tile-base-golden-leftover-height) var(--mobile-tile-base-golden-height);
}

.product-mobile-tile-not-first {
    border-top: 1px solid rgba(177,177,177,1); /* Add a border between tiles */
}

  
.product-mobile-name {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  font-weight: 800;
  font-size: 3vh;
}

.product-mobile-image {
  grid-column: 4 / 6;
  grid-row: 1 / 2;
  width: 100%;
}

.product-mobile-price {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  text-align: left;
  font-size: 8vw;
}

.product-mobile-reserve-or-increment-container {
  grid-column: 4 / 6;
  grid-row: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.mobile-reserve-button {
  display: inline-block;
  /* width: calc(var(--poster-max-width) / 11); */
  width: 100%;
  height: 100%;
  padding: 2vh;
  background-color: white;
  border: none;
  border-bottom: 1px solid rgba(177,177,177,1);
  font-size: 3vh;
  color: rgb(30, 30, 30);
  cursor: pointer;
  transition: all 0.2s ease;
}
@media (hover: hover){
  .mobile-reserve-button:hover {
    border-bottom: 1px solid rgba(177,177,177,1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
}


.product-mobile-marketing-features-container {
  display: none;
}

.product-mobile-marketing-features-container-expanded {
  margin-top: 10vh;
  display: block;
  grid-column: 1 / 6;
  grid-row: 3 / 4;
  height: var(--mobile-tile-base-height);
} 
